import { useRouter } from 'next/router';
import { useEffect } from 'react';
export const IndexRedirect = ({
  redirectRoute
}: {
  redirectRoute: string;
}) => {
  const router = useRouter();
  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    void router.replace(redirectRoute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);
  return <></>;
};